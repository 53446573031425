







































import VueBase from '@/VueBase'
import { Component, Emit, Prop } from 'vue-property-decorator'
import emitter from './Emitter'
@Component({
  name: 'LinkBar',
})
export default class LinkBar extends VueBase {
  @Prop() showType: any
  projects: any = []
  versionList: any = []
  requestchains = []
  full = []
  project_id = ''
  version_id = ''
  level = ''
  full_id = ''
  showSearch = false

  private changeLevel = (e: any) => {
    if (!e) {
      return
    }
    emitter.emit('level', e)
  }

  private async SearchFull(name: string) {
    const res = await this.services.link.fuzzsearch({
      name: name,
    })
    if (res.status === 201) {
      this.full = res.data
    }
  }
  private clearFull() {
    this.full = []
    this.full_id = ''
  }

  private async enterProject(e: any) {
    // emitter.emit('clearfun')
    this.version_id = ''
    this.getVersionList()
    // this.enterSearch()
  }

  private async getProjects(name?: string | undefined) {
    const res = await this.services.project.projectList({
      page: 1,
      pageSize: 50,
      name: name,
    })
    if (res.status === 201) {
      this.projects = res.data
    }
  }

  private async getVersionList(token?: string | undefined) {
    const res = await this.services.project.versionList(this.project_id)
    if (res.status === 201) {
      this.versionList = res.data
    }
  }

  private async remoteProject(query: string) {
    this.getProjects(query)
  }

  private enterSearch() {
    if (!this.project_id) {
      this.$message.warning('请选择应用名称')
      return null
    } else if (!this.version_id) {
      this.$message.warning('请选择版本号')
      return null
    }
    this.enter()
  }

  @Emit('enter')
  private enter() {
    return [this.project_id, this.version_id]
  }

  mounted() {
    emitter.on('clearLevel', () => {
      // this.level = ''
    })
  }

  beforeDestroy() {
    emitter.off('clearLevel', () => {
      console.log('clearLevel')
    })
  }

  created() {
    this.getProjects()
  }
}
