





























































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import LinkBar from '@/views/links/linkBar.vue'
import LinkTree from './components/linkTree.vue'
import Topology from './components/topologyGraph.vue'
import LinkList from '@/views/links/linkList.vue'
import VueBase from '@/VueBase'

import Distribution from './components/distribution.vue'
import Trend from './components/trend.vue'
import Type from './components/type.vue'

import { formatTimestamp } from '@/utils/utils'
import emitter from './Emitter'
import ProjectCard from './components/projectCard.vue'

@Component({
  name: 'TaintIndex',
  components: {
    LinkBar,
    LinkTree,
    Topology,
    LinkList,
    Distribution,
    Trend,
    Type,
    ProjectCard,
  },
})
export default class Index extends VueBase {
  @Prop() projectId?: any
  @Prop() versionId?: any
  private isFull = false
  private rightActive = false
  private active = false
  private status = false
  private data: any = null
  private start_time = ''
  private span = ''
  private showType = 'tp'
  private levelNum: any = {}
  private activeId = ''
  private rightBoxInfo: any = {
    type: 'server',
    level_count: [{}, {}, {}, {}, {}],
  }
  private radio: any = 'link'
  private size: any = 50
  formatTimestamp(time: number) {
    return formatTimestamp(time)
  }

  get sca_setup(): any {
    return this.$store.state.user.userInfo?.sca_setup
  }

  private zoom(zoom: number) {
    const linkPicture: any = this.$refs.linkPicture
    linkPicture.GraphHandel.zoom(zoom, null, true, {
      duration: 100,
    })
  }

  private changeType(type: string) {
    emitter.emit('clearfun')
    this.showType = type
  }

  private async toLink(item: any) {
    this.history = [this.project_id, this.project_version_id]
    const ref: any = this.$refs
    ref.SearchBar.project_id = item.project_id
    ref.SearchBar.version_id = item.project_version_id
    await this.search([item.project_id, item.project_version_id])
    this.radio = 'link'
  }
  private async openRight(item: any) {
    let [flag, data] = item
    if (this.projectId) {
      return
    }
    if (flag) {
      this.loadingStart()

          const summaryRes = await this.services.project.projectsSummary(
            data.project_id, data.project_version_id
          )
          if (summaryRes.status !== 201) {
            this.$message.error(summaryRes.message)
            return
          }
          this.rightBoxInfo = { ...summaryRes.data, type: 'project' }
      this.loadingDone()
    }
    this.rightActive = flag
  }

  private toDetail(id: any) {
    this.$router.push({
      path: '/project/projectDetail/' + id,
    })
  }

  private onError() {
    this.$message({
      message: '复制失败！',
      type: 'error',
    })
  }

  private history: any = []

  private async back() {
    const ref: any = this.$refs
    ref.SearchBar.project_id = this.history[0]
    ref.SearchBar.version_id = this.history[1]
    await this.search(this.history)
    this.radio = 'topology'
  }

  private alltotal: any = 0
  private json_data: any = require('../../json_data.json')
  private proto: any = []
  private project_id: any = ''
  private project_version_id: any = ''
  private async search([project_id, project_version_id]: any[]) {
    this.project_id = project_id
    this.project_version_id = project_version_id
    this.radio = 'topology'
    this.getTopo()
    this.getLink()
  }

  async getLink(project_id?: any, project_version_id?: any) {
    this.data = null
    this.alltotal = 0
    this.loadingStart()
    const res = await this.services.link.getProjectTopology({
      project_id: project_id || this.project_id,
      project_version_id: project_version_id || this.project_version_id,
      size: this.size > 0 ? this.size : undefined
    })
    this.loadingDone()
    if (res.status === 201) {
      this.levelNum = res.data.stat
      this.changeActive()
      let node = res.data.nodes[0]
      let json: any = res.data
      json.nodes = json.nodes.map((item: any) => {
        return {
          id: item.node_tag,
          label: this.formatLabel(item.project_name+item.project_version_name),
          ...item
        }
      })
      json.edges = json.vecs.map((item: any) => {
        return {
          source: item.source_node_tag,
          target: item.target_node_tag,
          ...item
        }
      })
      delete json.vecs
      this.proto = json
      let tree = this.build_tree(json)
      if (node.graph_hash) {
        let nodeTree: any = await this.getLinkByHash(node)
        let temp: any = tree.children?.find((item: any) => item.node_tag == node.node_tag) || {}
        temp.children = nodeTree.children
      }

      this.data = tree
      this.alltotal = res.page.alltotal
      // console.log('this.data', this.data)
      // console.log('json', json)
      // this.data = {nodes: json.nodes, edges}
    } else {
      this.closeActive()
      this.$message.error(res.msg)
    }
  }

  build_tree(data: any) {
    let { nodes, edges } = data
    edges?.forEach((vec: any) => {
      let parent = nodes.find((node: any) => node.node_tag === vec.source_node_tag)
      let child = nodes.find((node: any) => node.node_tag === vec.target_node_tag)
      parent.children = parent.children || []
      parent.children.push(child)
    });
    let root = nodes.find((node: any) => !node.graph_hash)
    root.root = true
    return root
  }

  async getLinkByHash(node: any) {
    const {data, status} = await this.services.link.getProjectTopologyByHash(node.graph_hash)
    if (status != 201) return {nodes: [], vecs:[]}
    let json: any = {}
      json.nodes = data.nodes.map((item: any) => {
        return {
          id: item.node_tag,
          label: this.formatLabel(item.project_name+item.project_version_name),
          ...item
        }
      })
      json.edges = data.vecs.map((item: any) => {
        return {
          source: item.source_node_tag,
          target: item.target_node_tag,
          ...item
        }
      })
      this.proto.nodes.push(...json.nodes)
      this.proto.edges.push(...json.edges)
    let nodeTree = this.node_tree(json, node)
    return nodeTree
  }

  node_tree(data: any, node: any) {
    let { nodes, edges } = data
    nodes = [...nodes, node]
    edges?.forEach((vec: any) => {
      let parent = nodes.find((node: any) => node.node_tag === vec.source_node_tag)
      if (parent) {
        let child = nodes.find((node: any) => node.node_tag === vec.target_node_tag)
        parent.children = parent.children || []
        parent.children.push(child)
      }

    });
    return node
  }

  private dataTopology: any = null
  private loadingTopo: boolean = false
  async getTopo() {
    this.dataTopology = null
    this.loadingTopo = true
    let { data, status } = await this.services.link.getEntryGraph({
      project_id: this.project_id,
      project_version_id: this.project_version_id,
      graph_hashs: ['1']
    })
    this.loadingTopo = false

    if (status != 201) return
    let { nodes, vecs } = data
    let json:any = {}
    json.nodes = data.nodes.map((item: any) => {
      return {
        id: String(item.project_version_id),
        label: this.formatLabel(item.project_name+item.project_version_name),
        // label: item.project_name+item.project_version_name,
        ...item
      }
    })
    json.edges = data.vecs.map((item: any) => {
      return {
        source: String(item.source_project_version_id),
        target: String(item.target_project_version_id),
        ...item
      }
    })
    this.dataTopology = json
    // console.log('this.dataTopologyt',data, this.dataTopology)
  }

  formatLabel(str: any) {
    let result = '';
    for (let i = 0; i < str.length; i++) {
      result += str[i];
      if ((i + 1) % 15 === 0) {
        result += '\n';
      }
    }
    return result
  }

  sizeChange() {
    this.getLink()
  }

  private changeActive() {
    this.active = true
    setTimeout(() => {
      this.status = true
    }, 400)
  }

  private closeActive() {
    this.status = false
    setTimeout(() => {
      this.active = false
    }, 400)
  }

  @Watch('versionId', { deep: true })
  onDataChanged(val: any) {
    if (this.projectId) {
      this.getLink(this.projectId, this.versionId)
    }
  }

  created() {
    if (this.projectId) {
      this.getLink(this.projectId, this.versionId)
    }
  }
}
