






import { Component, Prop, Emit } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import G6 from '@antv/g6'

@Component({
  name: 'LinkTree',
})
export default class LinkTree extends VueBase {
  @Prop() data!: any
  @Prop() proto!: any

  @Emit('openRight')
  private openRight(flag: any, node: any) {
    return [flag, node]
  }
  private loadingLL: boolean = false
  GraphHandel: any = null
  private async run(height: number, width: number) {
    this.formatData(this.proto.edges)
    const graph = new G6.TreeGraph({
      container: 'mountNode', // String | HTMLElement，必须，在 Step 1 中创建的容器 id 或容器本身
      // plugins: [tooltip],
      height,
      width,
      modes: {
        default: [
          'drag-canvas',
          'zoom-canvas',
        ],
      },
      defaultNode: {
        size: 30,
        style: {
          fill: '#FBE9E9',
          stroke: '#DC2626',
          lineWidth: 4,
          cursor: 'pointer',
        },
        labelCfg: {
          position: 'bottom',
          offset: 10,
          style: {
            fill: '#111827',
            cursor: 'pointer'
          }
        }
      },
      defaultEdge: {
        style: {
          lineWidth: 2
        }
      },
      layout: {
        type: 'mindmap',
        direction: 'H',
        getHeight: () => {
          return 32;
        },
        getWidth: () => {
          return 32;
        },
        getVGap: () => {
          return 36;
        },
        getHGap: () => {
          return 100;
        },
      },
    })

    this.GraphHandel = graph
    
    graph.node((node: any) => {
      let { vul_levels } = node
      vul_levels.forEach((item: any) => {
        switch (item.level) {
          case 1:
            item.fillColor = '#FBE9E9'
            item.borderColor = '#EF4444'
            item.shadowColor = '#FEE2E2'
            break;
          case 2:
            item.fillColor = '#FEF5E7'
            item.borderColor = '#F59E0B'
            item.shadowColor = '#FEF3C7'
            break;
          case 3:
            item.fillColor = '#E7F6FD'
            item.borderColor = '#0EA5E9'
            item.shadowColor = '#E0F2FE'
            break;
          case 5:
            item.fillColor = '#EFF1F3'
            item.borderColor = '#64748B'
            item.shadowColor = '#E2E8F0'
            break;
          default:
            item.fillColor = '#E9EFFD'
            item.borderColor = '#2563EB'
            item.shadowColor = '#DBEAFE'
            break;
        }
      })

      let height = vul_levels.find((item: any) => item.level == 1)
      height.size = String(height.total).length
      node.height = height

      let med = vul_levels.find((item: any) => item.level == 2)
      med.size = String(med.total).length
      node.med = med

      let low = vul_levels.find((item: any) => item.level == 3)
      low.size = String(low.total).length
      node.low = low
      
      let info = vul_levels.find((item: any) => item.level == 5)
      info.size = String(info.total).length
      node.info = info

      node.target_vul_level = this.findMaxTotalObject(vul_levels)

      return {
        type: 'nodeName'
      };
    });

    let centerX = 0;
    G6.registerNode('nodeName', {
      draw(cfg: any, group:any) {
        const { id, label, root, vul_levels, target_vul_level,
          height, med, low, info } = cfg
        if (root) {
          centerX = cfg.x;
        }
        // 创建一个圆形节点
        group.addShape('circle', {
          attrs: {
            r: 10,
            fill: target_vul_level.shadowColor,
          },
          name: 'bg'
        });
        const node = group.addShape('circle', {
          attrs: {
            r: 15,
            fill: target_vul_level.fillColor,
            stroke: target_vul_level.borderColor,
            lineWidth: 4,
            cursor: 'pointer',
          },
          name: 'node'
        });
        group.addShape('text', {
          attrs: {
            text: label,
            x: 0, // 标签的位置
            y: 26, // 标签在节点中心
            textAlign: 'center',
            textBaseline: 'top',
            cursor: 'pointer',
            fill: '#111827', // 标签的颜色
            fontSize: 12, // 标签的字体大小
          },
          name: 'label'
        });
        // 70 35

        let all = (height.size + med.size + low.size + info.size + 3) * 7
        
        let heightX = -(all/2)
        let medX = heightX + (height.size + 1) * 7
        let lowX = medX + (med.size + 1) * 7
        let infoX = lowX + (low.size + 1) * 7
        group.addShape('text', {
          attrs: {
            text: height.total,
            x: heightX, // 标签的位置
            y: -30, // 标签在节点中心
            textAlign: 'start',
            textBaseline: 'middle',
            cursor: 'pointer',
            fill: '#EF4444', // 标签的颜色
            fontSize: 12, // 标签的字体大小
            opacity: 0,
            fontWeight: 500
          },
          name: 'tip'
        });
        group.addShape('text', {
          attrs: {
            text: med.total,
            x: medX, // 标签的位置
            y: -30, // 标签在节点中心
            textAlign: 'start',
            textBaseline: 'middle',
            cursor: 'pointer',
            fill: '#F59E0B', // 标签的颜色
            fontSize: 12, // 标签的字体大小
            opacity: 0,
            fontWeight: 500
          },
          name: 'tip'
        });
        group.addShape('text', {
          attrs: {
            text: low.total,
            x: lowX, // 标签的位置
            y: -30, // 标签在节点中心
            textAlign: 'start',
            textBaseline: 'middle',
            cursor: 'pointer',
            fill: '#0EA5E9', // 标签的颜色
            fontSize: 12, // 标签的字体大小
            opacity: 0,
            fontWeight: 500
          },
          name: 'tip'
        });
        group.addShape('text', {
          attrs: {
            text: info.total,
            x: infoX, // 标签的位置
            y: -30, // 标签在节点中心
            textAlign: 'start',
            textBaseline: 'middle',
            cursor: 'pointer',
            fill: '#64748B', // 标签的颜色
            fontSize: 12, // 标签的字体大小
            opacity: 0,
            fontWeight: 500
          },
          name: 'tip'
        });
        ;(cfg.expandable || cfg.children) &&
        group.addShape('marker', {
          attrs: {
            x: cfg.x < centerX ? -16 : 16,
            y: 0,
            r: 6,
            cursor: 'pointer',
            symbol: (cfg.collapsed || (cfg.expandable&&!cfg.children)) ? G6.Marker.expand : G6.Marker.collapse,
            stroke: '#666',
            lineWidth: 1,
            fill: '#fff',
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'collapse-icon',
        });
        return node; // 返回节点作为 keyShape
      },
      setState(name: any, value: any, node: any) {
          const group = node.getContainer()
          const shapes = group.get('children') // 顺序根据 draw 时确定
          if (name === 'labelHover') {
            if (value) {
              shapes[2].attr('fill', '#2563EB' )
            } else {
              shapes[2].attr('fill', '#111827' )
            }
          } else if (name === 'nodeHover') {
            // const tips = node.get('group').filter((ele: any) => ele.get('name') === 'tip');
            if (value) {
              shapes[0].attr('r', '24' )
              shapes[1].attr('r', '16' )
              // tips.forEach((item: any) => {
              //   item.attr('opacity', 1)
              // })
              shapes[4].attr('opacity', 1)
              shapes[5].attr('opacity', 1)
              shapes[6].attr('opacity', 1)
              shapes[3].attr('opacity', 1)
            } else {
              shapes[0].attr('r', '10' )
              shapes[1].attr('r', '15' )
              // tips.forEach((item: any) => {
              //   item.attr('opacity', 0)
              // })
              shapes[4].attr('opacity', 0)
              shapes[5].attr('opacity', 0)
              shapes[6].attr('opacity', 0)
              shapes[3].attr('opacity', 0)
            }
          } else if (name === 'collapsed') {
              // const marker = node.get('group').find((ele: any) => ele.get('name') === 'collapse-icon');
              const icon = value ? G6.Marker.expand : G6.Marker.collapse;
              shapes[7].attr('symbol', icon);
            }
        },
    });

    graph.edge((node: any) => {
      const { target } = node
      return {
        type: 'customEdge',
        style: {
          stroke: this.edgeColor[target],
          lineWidth: 2
        },
      };
    });

    graph.on('node:click', async (e: any) => {
      let name = e.target.get('name')
      let model = e.item.getModel()
      if (name === 'collapse-icon') {
        if (!model.children) {
          let nodeTree = await this.getLinkByHash(model)
          model.children = nodeTree?.children
          graph.updateChild(model, model.id)
          graph.setItemState(e.item, 'collapsed', model.collapsed);
        } else {
          model.collapsed = !model.collapsed;
          graph.setItemState(e.item, 'collapsed', model.collapsed);
          graph.layout();
        }
      } else if (name === 'label') {
        this.openRight(true, e.item.get('model'))
      }
    });
    graph.on('click', (e: any) => {
      if (e.target.get('name') === 'label') return
      this.openRight(false, null)
    })
    graph.on('node:mouseenter', (e: any) => {
      const model = e.item.get('model')
      let name = e.target.get('name')
      if (name === 'label') {
        graph.setItemState(e.item, 'labelHover', true)
      } else if (name === 'node') {
        graph.setItemState(e.item, 'nodeHover', true)
      }
    })
    graph.on('node:mouseleave', (e: any) => {
      graph.setItemState(e.item, 'labelHover', false)
      graph.setItemState(e.item, 'nodeHover', false)
    })

    graph.data(this.data) // 读取 Step 2 中的数据源到图上
    graph.render() // 渲染图
    // 自适应
    // graph.fitView()
    // 定位到画布中心
    graph.fitCenter()

  }

  async getLinkByHash(node: any) {
    this.loadingLL = true
    const {data, status} = await this.services.link.getProjectTopologyByHash(node.graph_hash)
    this.loadingLL = false
    if (status != 201) return
    let json: any = {}
      json.nodes = data.nodes.map((item: any) => {
        return {
          id: item.node_tag,
          label: this.formatLabel(item.project_name+item.project_version_name),
          ...item
        }
      })
      json.edges = data.vecs.map((item: any) => {
        return {
          source: item.source_node_tag,
          target: item.target_node_tag,
          ...item
        }
      })
    this.formatData(json.edges)
    let nodeTree = this.node_tree(json, node)
    return nodeTree
  }
  formatLabel(str: any) {
    let result = '';
    for (let i = 0; i < str.length; i++) {
      result += str[i];
      if ((i + 1) % 15 === 0) {
        result += '\n';
      }
    }
    return result
  }
  node_tree(data: any, node: any) {
    let { nodes, edges } = data
    nodes = [...nodes, node]
    edges?.forEach((vec: any) => {
      let parent = nodes.find((node: any) => node.node_tag === vec.source_node_tag)
      if (parent) {
        let child = nodes.find((node: any) => node.node_tag === vec.target_node_tag)
        parent.children = parent.children || []
        parent.children.push(child)
      }
      
    });
    return node
  }

  findMaxTotalObject(arr:any) {
    arr = this.sortByLevel(arr)
    let maxTotalObject = arr[0];
    for (let i = 1; i < arr.length; i++) {
      if (arr[i].total > maxTotalObject.total) {
        maxTotalObject = arr[i];
      }
    }
    if (maxTotalObject.total == 0) {
      maxTotalObject.fillColor = '#E9EFFD'
      maxTotalObject.borderColor = '#2563EB'
      maxTotalObject.shadowColor = '#DBEAFE'
    }
    return maxTotalObject;
  }
  sortByLevel(arr: any) {
    // 小 大
    return arr.sort((a: any, b:any) => a.level - b.level);
  }

  private colorMap: any = ['#14B8A6', '#A855F7', '#F97316','#0EA5E9','#EC4899','#84CC16','#3B82F6']
  private edgeColor: any = {}
  private hashColor: any = {}
  formatData(edges: any) {
    let size: number = 0
    edges.forEach((item: any, index: any) => {
      let hash = this.hashColor[item.graph_hash]
      if (hash) {
        this.edgeColor[item.target_node_tag] = hash
      } else {
        this.hashColor[item.graph_hash] = this.colorMap[size%8]
        this.edgeColor[item.target_node_tag] = this.colorMap[size%8]
        size++
      }
    });
  }

  mounted() {
    var clientHeight = document.body.clientHeight
    var clientWidth = document.body.clientWidth
    this.run(clientHeight-100, clientWidth-32)
  }
}
